.App {
  text-align: center;
}

@media (max-width: 3800px) {
  .hero-title {
    font-size: 1.5rem;
  }
  .hero-description {
    font-size: 4.5rem;
  }
  .hero-link {
    font-size: 2rem;
  }
}
@media (max-width: 600px) {
  .hero-title {
    font-size: 0.875rem;
  }
  .hero-description {
    font-size: 2rem;
  }
  .hero-link {
    font-size: 1.2rem;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(17deg);
  }
}

/* Navbar */

.navbar {
  padding-bottom: 1px;
  border-bottom: solid 1px #e8e8e8;
  background-color: white;
  box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.12),
    0 6px 16px -11px rgba(0, 0, 0, 0.08);
  position: sticky;
  top: 0;

  z-index: 99990;
}

.nav-header {
  background-color: white;
  border-bottom: 0;
  padding: 0 4px 0 24px;
}

.logo {
  width: 200px;
  float: left;
}

.logo a {
  display: inline-block;
  font-size: 20px;
  text-transform: capitalize;
}

.navbar-menu {
  width: calc(100% - 200px);
  float: left;
}
.navbar-menu .ant-layout-header {
  padding: 0 20px;
}

.navbar-menu .ant-menu-horizontal {
  border-bottom: 0;
}

.navbar-menu .ant-menu-item {
  padding-bottom: 0px;
}

.navbar-menu .leftMenu {
  float: left;
}

.navbar-menu .rightMenu {
  float: right;
}

.menuButton {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 14px;
  display: none;
  background: none;
  margin-right: 10px;
}

.ant-drawer-body {
  padding: 0;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

.username {
  display: none;
  margin-left: 8px;
}

@media screen and (max-width: 767px) {
  .menuButton {
    display: block;
    margin-right: 10px;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -10px;
  }

  .ant-drawer-title > .brand-font {
    margin-bottom: 0;
  }

  .username {
    display: inline-block;
  }
}

/* Navbar End */

/* start whatsapp messenger */

.message-bubble {
  max-width: 60%;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  font-size: 14px;
  position: relative;
  line-height: 1.4;
}

.incoming {
  background-color: #fff;
  color: #333;
  margin-right: auto;
  border-radius: 8px 8px 8px 0;
}

.outgoing {
  background-color: #dcf8c6;
  color: #333;
  margin-left: auto;
  border-radius: 8px 8px 0 8px;
}

.timestamp {
  font-size: 10px;
  color: #888;
  position: absolute;
  bottom: -12px;
  right: 10px;
}

.active-contact {
  background-color: #e5e5e5;
}

.ant-layout {
  height: 100vh;
}

.ant-layout-sider {
  background: #f5f5f5;
}

.ant-layout-content {
  padding: 20px;
  background-color: #e5ddd5;
  display: flex;
  flex-direction: column;
}

.ant-layout-footer {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
}

/* end whatsapp messager  */
